const CFCategories = {
    direct: [
        {   
            id: 'dc0',
            index: 0,  
            title: 'Платежи по закупке сырья и материалов',
            section: '1.2', 
            type: 'spending'
        },
        {
            id: 'dc1',
            index: 1,  
            title: 'Платежи за инструмент и оснастку',
            section: '1.2', 
            type: 'spending'
        },
        {
            id: 'dc2',
            index: 2,  
            title: 'Платежи по субподрядным работам',
            section: '1.2', 
            type: 'spending'
        },
        {
            id: 'dc3', 
            index: 3, 
            title: 'Платежи выплат банковских расходов на контракт',
            section: '1.2', 
            type: 'spending'
        },
        {
            id: 'dc4',
            index: 4,  
            title: 'Платежи выплат по прямым коммерческим расходам',
            section: '1.2', 
            type: 'spending'
        },
        {   
            id: 'dc5',
            index: 5,  
            title: 'Платежи по оплате патентных и иных лицензионных платежей',
            section: '1.2', 
            type: 'spending'
        },
        {   
            id: 'dc6',
            index: 6,  
            title: 'Платежи по страхованию на контракт',
            section: '1.2', 
            type: 'spending'
        },
        {
            id: 'dc7',
            index: 7,  
            title: 'Платежи по оплате транспортных услуг',
            section: '1.2', 
            type: 'spending'
        },
        {
            id: 'dc8',
            index: 8,  
            title: 'Платежи по оплате складских услуг',
            section: '1.2', 
            type: 'spending'
        },
        {
            id: 'dc9',
            index: 9,  
            title: 'Платежи по оплате прочих прямых расходов',
            section: '1.2', 
            type: 'spending'
        },
    ],
    labor: [
        {  
            id: 'lb0', 
            index: 0, 
            title: 'Платежи по оплате труда (без НДФЛ)',
            section: '1.3', 
            type: 'spending'
        },
        {  
            id: 'lb1', 
            index: 1, 
            title: 'Страховые взносы на ФОТ',
            section: '1.6', 
            type: 'spending'
        },
    ],
    commercial: [
        {  
            id: 'cm0', 
            index: 0, 
            title: 'Платежи по оплате PR и взаимодействия со СМИ',
            section: '1.4', 
            type: 'spending'
        },
        {
            id: 'cm1',
            index: 1,  
            title: 'Платежи по оплате меркетинговых услуг',
            section: '1.4', 
            type: 'spending'
        },
        {   
            id: 'cm2',
            index: 2,  
            title: 'Платежи по оплате рекламных услуг',
            section: '1.4', 
            type: 'spending'
        },
        {
            id: 'cm3', 
            index: 3, 
            title: 'Платежи по оплате расходов на проведение выставок',
            section: '1.4', 
            type: 'spending'
        },
        {
            id: 'cm4', 
            index: 4, 
            title: 'Платежи по прочим коммерческим расходам',
            section: '1.4', 
            type: 'spending'
        },
    ],
    common: [
        {
            id: 'co0', 
            index: 0, 
            title: 'Материальные затраты', 
            section: '1.4', 
            type: 'spending'
        },
        {
            id: 'co1',
            index: 1, 
            title: 'Платежи по оплате ТЭР и коммунальных платежей',
            section: '1.4', 
            type: 'spending'
        },
        {
            id: 'co2',
            index: 2,  
            title: 'Платежи по оплате аренды',
            section: '1.4', 
            type: 'spending'
        },
        {
            id: 'fo1',
            index: 2,  
            title: 'Платежи по оплате кредитов и займов (в т.ч. лизинг)',
            section: '1.5', 
            type: 'spending'
        },
        {   id: 'co3', 
            index: 3, 
            title: 'Платежи по оплате ремонта и обслуживания активов',
            section: '1.4', 
            type: 'spending'
        },
        {   
            id: 'co4', 
            index: 4, 
            title: 'Платежи по оплате страхования',
            section: '1.4', 
            type: 'spending'
        },
        {   id: 'co5', 
            index: 5, 
            title: 'Платежи за услуги связи',
            section: '1.4', 
            type: 'spending'
        },
        {   id: 'co6', 
            index: 6, 
            title: 'Платежи на IT (техподдержка и обслуживание)',
            section: '1.4', 
            type: 'spending'
        },
        {   id: 'co17', 
            index: 7, 
            title: 'Платежи за банковское обслуживание', 
            section: '1.4', 
            type: 'spending'
        },
        {   id: 'co18', 
            index: 7, 
            title: 'Платежи за бухгалтерское обслуживание', 
            section: '1.4', 
            type: 'spending'
        },
        {   id: 'co7', 
            index: 7, 
            title: 'Платежи за аудиторские услуги', 
            section: '1.4', 
            type: 'spending'
        },
        {   id: 'co8', 
            index: 8, 
            title: 'Платежи за юридические услуги',
            section: '1.4', 
            type: 'spending'
        },
        {   id: 'co9', 
            index: 9, 
            title: 'Платежи за консультационные услуги',
            section: '1.4', 
            type: 'spending'
        },
        {   id: 'co10', 
            index: 10, 
            title: 'Платежи по оплате услуг по охране',
            section: '1.4', 
            type: 'spending'
        },
        {   id: 'co16', 
            index: 11, 
            title: 'Платежи на социальные программы и обучение',
            section: '1.4', 
            type: 'spending'
        },
        {   id: 'co11', 
            index: 12, 
            title: 'Платежи за транспортные услуги',
            section: '1.4', 
            type: 'spending'
        },
        {   id: 'co12', 
            index: 13, 
            title: 'Платежи за уборку территорий, вывоз мусора',
            section: '1.4', 
            type: 'spending'
        },
        {   id: 'co13', 
            index: 14, 
            title: 'Платежи за стандартизацию, сертификацию производства',
            section: '1.4', 
            type: 'spending'
        },
        {   id: 'co14', 
            index: 15, 
            title: 'Экологические платежи',
            section: '1.4', 
            type: 'spending'
        },
        {   id: 'co15', 
            index: 16, 
            title: 'Платежи на прочие услуги и работы',
            section: '1.4', 
            type: 'spending'
        },
        {   id: 'co17', 
            index: 17, 
            title: 'Ппрочие постоянные расходы по текущей деятельности',
            section: '1.4', 
            type: 'spending'
        },
    ],
}

export default CFCategories;