<template>
  <div class="w-100 pl-3">
    
    <!-- Баннер нулевых данных  -->
    <div v-if="commonExpnsList.length == 0"
      class="card mt-3 mb-3">
      <div class="bg-holder d-none d-lg-block bg-card" 
      style="background-image:url(/img/illustrations/corner-4.png);background-position: center; background-size: cover">
      </div>
      <!--/.bg-holder-->
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="mb-0">Общехозяйственные расходы</h3>
            <div v-if="false" 
              class="btn btn-outline-light btn-sm fs--0 font-weight-light video-btn" >
              <i class="fas fa-play-circle mr-1"></i>
              Ознакомиться
            </div>
            <hr>
            <h5>На что еще вам придется потратить деньги?</h5> 
            
            <p class="mt-2 mb-0 text-800">
              Добавьте сюда все постоянные (не зависящие от объема продаж) расходы Вашей компании. Это может быть арендная плата, коммунальные платежи, 
              страхование, расходы на общехозяйственный персонал (секретари, ресепшн, колл-центр, весь управленческий персонал), офисные принадлежности и так далее. 
              Если ваша компания только начинает свою работу, не забудьте включить любые одноразовые или краткосрочные расходы на запуск в первые месяцы работы.
              </p>
            <p v-if="introDescExpanded == false" 
              class="mt-2 mb-0 text-800">
              <a 
                data-toggle="collapse" 
                href="#readMoreAboutDirectCosts" 
                role="button" 
                aria-expanded="false" 
                aria-controls="readMoreAboutDirectCosts"
                @click="introDescExpanded = true">
                Подробнее ...
              </a>
            </p>
            <div class="collapse" id="readMoreAboutDirectCosts">
                <p class="mt-2 text-800">
                  Убедитесь, что не указываете следующие расходы (они отражаются в другом месте):
                </p>
                <p class="mt-2 text-800">
                  <ol>
                    <li>
                      Платежи по кредитнам и займам. Мы рассчитаем их, для вас, на этапе финансирования.
                    </li>
                    <li>
                      Крупные приобретения (машины, оборудования). Их отразим в разделе - "Основные средства"
                    </li>
                    <li>
                      Покупка сырье или товаров для продажи. Это все указывается в разделе - "Себестоимость"
                    </li>
                    <li>
                      Износ и амортизацию. Они автоматически расчитаются в разделе - "Основные средства"
                    </li>
                  </ol> 
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <!-- Графики -->
    <div v-if="commonExpnsList.length > 0" 
      class="card mb-3 mt-3 w-100">
      <div class="card-body rounded-soft bg-gradient pt-0 pb-1 red-chart-bg">
        <div class="card-title">Коммерческие расходы</div>
        <div class="card-subtitle">
          {{activeBPlan.startYear + selectedYear}}
        </div>
        <div class="row mt-2">
          <div class="col-sm-8">
            <forecast-chart-light class="w-100"
              :chartData="tableData.selectedYearChartData">
            </forecast-chart-light>
          </div>
          <div class="col-sm-4 pl-4">
            <forecast-years-chart-light
              :chartData="tableData.yearsChartData">
            </forecast-years-chart-light>
          </div>
        </div>
      </div>            
    </div>

    <!-- Строка табличных данных -->
    <div v-if="commonExpnsList.length > 0"
      class="row w-100 m-0">
      <!-- Таблица с детализацией выручки по году -->
      <div class="col-sm-12 px-0">
        <div class="card mb-2 w-100">
          <div class="card-header d-flex justify-content-end py-2 pr-0">
            <div class="col-sm-auto d-flex flex-center fs--1 mt-1 mt-sm-0">
                           
            </div>
          </div>
  
          <div class="card-body p-0">
            <div class="table-responsive">
              <table-section-render 
                :tableData="tableData"
                @activeYearSelect="(year) => selectedYear = year" 
                @openToEdit="openToEdit" />
            </div>
          </div>
          <div class="card-footer p-0">
            <small class="ml-2 fs--2">

            </small>
          </div>
        </div>
      </div>
    </div>
    <!-- Конец таблицы -->

    <hr>
    <div class="d-flex align-content-between row">
      <div class="col-6">
        <button 
          class="btn btn-outline-primary font-weight-normal mr-2"
          @click="createCommonExpns"
          v-b-modal.commonExpnsEditModal>
          Добавить расход 
        </button>
      </div>
      <div class="col-6 text-right">
        <router-link to="/finance/commercial-expenses"
          class="btn btn-outline-secondary btn-sm ml-3">
          <i class="fas fa-chevron-left"></i>
          Коммерческие расходы
        </router-link>
        <router-link to="/finance/assets" 
          class="btn btn-outline-primary btn-sm ml-3">
          Основные средства
          <i class="fas fa-chevron-right"></i>
        </router-link>
      </div>
    </div>
    

    <common-expns-edit-modal />

    
  </div>
</template>

<script>
import ForecastChartLight from '@/components/app/charts/ForecastChartLight';
import ForecastYearsChartLight from '@/components/app/charts/ForecastYearsChartLight';
import CommonExpnsEditModal from '../../components/app/modals/CommonExpnsEditModal';

import TableSectionRender from '../../components/app/Tables/TableSectionRender';


export default {
  data: () => ({
    selectedYear: 0,
    introDescExpanded: false,
  }),

  components: {
    ForecastChartLight,
    ForecastYearsChartLight,
    CommonExpnsEditModal,
    TableSectionRender
  },

  mounted() {
    this.$store.dispatch('fetchCommonExpnsList')
  },

  computed: {
    activeBPlan() {
      return this.$store.getters.getActiveBPlan;
    },

    commonExpnsList() {
      return this.$store.getters.getCommonExpnsList
    },

    tableData() {
      // Загружаем данные по активам из базы данных
      const list = this.commonExpnsList || []
      // Переменная для последующего вычисления итогов таблицы
      var tableRows = []
      // Переменная для вычисления информации для графика выбранного года
      var chartData = []
      // Переменная для вычисления информации для графика данных по годам
      var chartYearsData = []
      // Переменная для передачи форматированной таблицы из функции
      var payload = []

      /* 
        По каждой из позиции производим форматирование даных для отображения.
      */
      
      // Пеоеменая для хранения форматированных данных
      var formatedValues, yearData = []
      
      for (var i = 0; i < list.length; i++) {
        // Берем каждую позицию и производим ее форматирование
        formatedValues = []
        yearData = []
        for (var y = 0; y < Object.values(list[i].values).length; y++) {
          const values = Object.values(list[i].values)[y]
          /* 
            Если расмматриваемый год равен выбранному, то группируем данные
            по квартально и добавляем сумму по году
          */
          if (y == this.selectedYear) {
            formatedValues.push(this.$func.arrSum(values.slice(0, 3))) // 1 кв.
            formatedValues.push(this.$func.arrSum(values.slice(3, 6))) // 2 кв.
            formatedValues.push(this.$func.arrSum(values.slice(6, 9))) // 3 кв.
            formatedValues.push(this.$func.arrSum(values.slice(9, 12))) // 4 кв.
            formatedValues.push(this.$func.arrSum(values)) // Итого по году
            chartData.push(values) // Данные для графика выбранного активного года
          } else {
            formatedValues.push(this.$func.arrSum(values)) // Итого по году
          }
          
          // Собираем данные по годам
          yearData.push(this.$func.arrSum(values));          
        }
       
        const data = {
          type: 'categoryItem',
          title: list[i].title,
          values: formatedValues
        }

        tableRows.push(formatedValues)
        payload.push(data)
        chartYearsData.push(yearData)
      }

      // Расчитываем итоговые значения футера таблицы
      var colsSum = this.$func.colSum(tableRows)
      // Расчитываем значения для графика по месяцам
      var selectedYearChartData = this.$func.colSum(chartData)
      // Расчитываем значения для графика по месяцам
      var yearsChartData = this.$func.colSum(chartYearsData)

      return { data: payload, total: colsSum, selectedYearChartData, yearsChartData }
    },

  },

  methods: {
    async openToEdit(index) {
      const commonExpnsItem = this.commonExpnsList[index];
      await this.$store.commit('setCommonExpnsToEdit', commonExpnsItem)
      this.$bvModal.show('commonExpnsEditModal')
    },

    createCommonExpns() {
      var payload = {
        title: '',
        titleId: null,
        titleType: null,
        type: 'general',
        values: {
          0: [null, null, null, null, null, null, null, null, null, null, null, null],
          1: [null, null, null, null, null, null, null, null, null, null, null, null],
          2: [null, null, null, null, null, null, null, null, null, null, null, null],
        },
      }

      this.$store.commit('createCommonExpns', payload)
    }
  }
}
</script>

<style lang="scss" scoped>
.video-btn {
  position: absolute;
  top: 0.3rem;
  right: 1rem;
  color: gray !important;
  border-color: gray !important;
}

thead, tfoot {
  font-family: 'Proxima Nova';
  background: #4d596961 !important;
}


.table-sm {
  td {
  height: 25px !important;
  line-height: 20px;
  padding: 2px !important;
  }
}


.title {
  height: 20px;
  &:hover {
    .edit-btn {
      display: inline;
    }
  }
  .edit-btn {
    color: rgba(145, 145, 145, 0.2);
    display: none;
    border: none !important;
    &:hover {
      color: rgb(145, 145, 145);
    }
  }
}

.bg-gradient {
  &.green {
    background-image: linear-gradient(-45deg, #0C271E, #17A674);
  }
  &.red {
    background-image: linear-gradient(-45deg, #b1010c, #db9e86);
  }
  &.neutral {
    background-image: linear-gradient(-45deg, #434a54, #8e959e);
  }
  .card-title {
    position: absolute;
    color: rgba(0,0,0,0.1);
    z-index: 0;
    font-size: 3.5rem;
    top: 0.7rem;
  } 
  .card-subtitle {
    position: absolute;
    color: rgba(0,0,0,0.1);
    z-index: 0;
    font-size: 4.5rem;
    font-weight: 600;
    top: 9rem;
    left: 46%;
    &.s2 {
      left: 3%;
    }
    &.s3 {
      left: 25%;
    }
  } 
  .graph-units {
    position: absolute;
    color: #ffffffb1;
    z-index: 0;
    font-size: 0.7rem;
    top: 0;
  }
}

.highcharts-background {
  background: #b1010c;
}

</style>